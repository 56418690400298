<template>
  <div id="EnrichProduct" class="container-fluid m-0 p-0">
    <div class="row m-0 p-3 justify-content-center">
      <div class="col-10">
        <b-overlay
          :show="load"
          spinner-variant="main"
          spinner-type="grow"
          spinner-small
        >
          <Boxdetail />
        </b-overlay>

        <b-overlay
          :show="load"
          spinner-variant="main"
          spinner-type="grow"
          spinner-small
        >
          <Productimages
            :key="$route.params.skcode + 4"
            @getpimenrichproduct="getpimenrichproduct"
          />
        </b-overlay>

        <b-overlay
          :show="load"
          spinner-variant="main"
          spinner-type="grow"
          spinner-small
        >
          <Generalinfomation :key="$route.params.skcode + 2" />
        </b-overlay>

        <b-overlay
          :show="load"
          spinner-variant="main"
          spinner-type="grow"
          spinner-small
        >
          <Productdescription :key="$route.params.skcode + 6" />
        </b-overlay>

        <b-overlay
          :show="load"
          spinner-variant="main"
          spinner-type="grow"
          spinner-small
        >
          <Productremark :key="$route.params.skcode + 8" />
        </b-overlay>

        <b-overlay
          :show="load"
          spinner-variant="main"
          spinner-type="grow"
          spinner-small
        >
          <Productother :key="$route.params.skcode + 10" />
        </b-overlay>

        <div class="row p-3 mb-2 sticky-bottom shadow-lg bg-white rounded">
          <div class="col-6">
            <!-- ------------ Create New Product --------- -->
            <!-- <div class="row" v-if="enrich == 'C' || enrich == 'Y'"> -->
            <div
              class="row"
              v-if="
                enrich !== '' &&
                  enrich !== 'A' &&
                  $store.getters['assigntasksActions/get_dataassign'] !== null
              "
            >
              <button
                type="button"
                class="btn btn-sm px-4 bt-vfx mx-1"
                v-if="
                  $roleaction('Req Missing Data') &&
                    can_update_cw &&
                    $store.getters['assigntasksActions/get_dataassign']
                      .copyWr_Creator_Status !== 'Y'
                "
                @click="RequestApproval('CW')"
                :disabled="
                  $store.getters['assigntasksActions/get_dataassign']
                    .copyWr_Creator_Status == 'Y' ||
                    $store.getters['assigntasksActions/get_dataassign']
                      .contactVd_Cw_Status !== 'Y'
                "
              >
                Request Approval CW
                {{
                  $store.getters["assigntasksActions/get_dataassign"]
                    .copyWr_Creator_Status == "R"
                    ? " After Reject"
                    : ""
                }}
                <span
                  v-if="
                    $store.getters['assigntasksActions/get_dataassign']
                      .copyWr_Creator_Status == 'Y'
                  "
                  class=""
                  v-html="$svg_icon.done_b"
                ></span>
              </button>
              <button
                type="button"
                class="btn btn-sm px-4 bt-vfx mx-1"
                v-if="
                  $roleaction('Req Image') &&
                    can_update_gd &&
                    $store.getters['assigntasksActions/get_dataassign']
                      .graphicDsn_Creator_Status !== 'Y'
                "
                @click="RequestApproval('GD')"
                :disabled="
                  $store.getters['assigntasksActions/get_dataassign']
                    .graphicDsn_Creator_Status == 'Y' ||
                    $store.getters['assigntasksActions/get_dataassign']
                      .contactVd_Gd_Status !== 'Y'
                "
              >
                Request Approval GD
                {{
                  $store.getters["assigntasksActions/get_dataassign"]
                    .graphicDsn_Creator_Status == "R"
                    ? " After Reject"
                    : ""
                }}
                <span
                  v-if="
                    $store.getters['assigntasksActions/get_dataassign']
                      .graphicDsn_Creator_Status == 'Y'
                  "
                  class=" "
                  v-html="$svg_icon.done_b"
                ></span>
              </button>
            </div>

            <!-- Approve/Reject for  Copy Writer -->
            <div
              class="row my-2"
              v-if="
                enrich !== '' &&
                  enrich !== 'A' &&
                  $store.getters['assigntasksActions/get_dataassign'] !==
                    null &&
                  String(CATTWD).length > 6
              "
            >
              <button
                type="button"
                class="btn btn-sm px-4 btn-info mx-1"
                v-if="
                  $roleaction('Approval Copy Writer') &&
                    $store.getters['assigntasksActions/get_dataassign']
                      .copyWr_Creator_Status == 'Y'
                "
                @click="Approval('CW')"
                :disabled="
                  $store.getters['assigntasksActions/get_dataassign']
                    .senior_copyWr_Staus == 'Y'
                "
              >
                Approval CW
                <span
                  v-if="
                    $store.getters['assigntasksActions/get_dataassign']
                      .senior_copyWr_Staus == 'Y'
                  "
                  class=""
                  v-html="$svg_icon.done_b"
                ></span>
              </button>
              <button
                type="button"
                class="px-4 btn-sm btn btn-outline-danger mx-1"
                v-if="
                  $roleaction('Approval Copy Writer') &&
                    $store.getters['assigntasksActions/get_dataassign']
                      .copyWr_Creator_Status == 'Y' &&
                    $store.getters['assigntasksActions/get_dataassign']
                      .senior_copyWr_Staus !== 'Y'
                "
                @click="Reject('CW')"
                :disabled="
                  $store.getters['assigntasksActions/get_dataassign']
                    .senior_copyWr_Staus == 'R'
                "
              >
                Reject CW
                <span
                  v-if="
                    $store.getters['assigntasksActions/get_dataassign']
                      .senior_copyWr_Staus == 'R'
                  "
                  class=""
                  v-html="$svg_icon.done_b"
                ></span>
              </button>

              <!-- Approve/Reject for  Graphic design -->
              <button
                type="button"
                class="btn btn-sm px-4 btn-info mx-1"
                v-if="
                  $roleaction('Approval Graphic design') &&
                    $store.getters['assigntasksActions/get_dataassign']
                      .graphicDsn_Creator_Status == 'Y'
                "
                @click="Approval('GD')"
                :disabled="
                  $store.getters['assigntasksActions/get_dataassign']
                    .senior_graphicDsn_Status == 'Y'
                "
              >
                Approval GD
                <span
                  v-if="
                    $store.getters['assigntasksActions/get_dataassign']
                      .senior_graphicDsn_Status == 'Y'
                  "
                  class=" "
                  v-html="$svg_icon.done_b"
                ></span>
              </button>
              <button
                type="button"
                class="px-4 btn-sm btn btn-outline-danger mx-1"
                v-if="
                  $roleaction('Approval Graphic design') &&
                    $store.getters['assigntasksActions/get_dataassign']
                      .graphicDsn_Creator_Status == 'Y' &&
                    $store.getters['assigntasksActions/get_dataassign']
                      .senior_graphicDsn_Status !== 'Y'
                "
                @click="Reject('GD')"
                :disabled="
                  $store.getters['assigntasksActions/get_dataassign']
                    .senior_graphicDsn_Status == 'R'
                "
              >
                Reject GD
                <span
                  v-if="
                    $store.getters['assigntasksActions/get_dataassign']
                      .senior_graphicDsn_Status == 'R'
                  "
                  class=" "
                  v-html="$svg_icon.done_b"
                ></span>
              </button>

              <button
                v-if="
                  $store.getters['assigntasksActions/get_dataassign']
                    .copyWr_Creator_Status == 'R'
                "
                class="px-4 btn-sm btn btn-danger mx-1 "
                @click="getDeatilReject($route.params.skcode, 'CW')"
              >
                Details rejected CW
              </button>
              <button
                v-if="
                  $store.getters['assigntasksActions/get_dataassign']
                    .graphicDsn_Creator_Status == 'R'
                "
                class="px-4 btn-sm btn btn-danger mx-1 "
                @click="getDeatilReject($route.params.skcode, 'GD')"
              >
                Details rejected GD
              </button>
            </div>
            <!-- ------------ Approval --------- -->

            <!-- ----------- Urgent Approve ------------------- -->
            <div
              class="row my-2"
              v-if="
                enrich !== '' &&
                  enrich !== 'A' &&
                  $store.getters['assigntasksActions/get_dataassign'] !==
                    null &&
                  String(CATBNB).length > 6 &&
                  String(CATTWD).length > 6
              "
            >
              <button
                type="button"
                class="btn btn-sm px-4 btn btn-outline-dark mx-1"
                v-if="
                  ($roleaction('Approval Copy Writer') &&
                    $store.getters['assigntasksActions/get_dataassign']
                      .copyWr_Creator_Status == 'R') ||
                    (enrich !== '' &&
                      enrich !== 'I' &&
                      $store.getters['assigntasksActions/get_dataassign']
                        .copyWr_Creator_Status !== 'R' &&
                      $store.getters['assigntasksActions/get_dataassign']
                        .senior_copyWr_Staus !== 'Y')
                "
                @click="UrgentApproval('CW')"
              >
                Urgent Approval CW
              </button>

              <button
                type="button"
                class="btn btn-sm px-4 btn btn-outline-dark mx-1"
                v-if="
                  ($roleaction('Approval Graphic design') &&
                    $store.getters['assigntasksActions/get_dataassign']
                      .graphicDsn_Creator_Status == 'R') ||
                    (enrich !== '' &&
                      enrich !== 'I' &&
                      $store.getters['assigntasksActions/get_dataassign']
                        .graphicDsn_Creator_Status !== 'R' &&
                      $store.getters['assigntasksActions/get_dataassign']
                        .senior_graphicDsn_Status !== 'Y')
                "
                @click="UrgentApproval('GD')"
              >
                Urgent Approval GD
              </button>
            </div>
            <!-- --------- Action after urgent --------------- -->
            <div
              class="col-12"
              v-if="UrgentLog.cw !== null || UrgentLog.gd !== null"
            >
              <!-- {{ UrgentLog }} -->

              <div
                class="row mb-2  align-items-center "
                v-if="UrgentLog.cw !== null"
              >
                <button
                  v-if="
                    UrgentLog.cw.status === 'NOFIX'
                      ? $roleaction('Req Missing Data')
                      : UrgentLog.cw.status === 'FIX'
                      ? $roleaction('Approval Copy Writer')
                      : false
                  "
                  type="button"
                  class="btn btn-sm "
                  :class="[
                    UrgentLog.cw.status === 'NOFIX'
                      ? 'btn-outline-secondary'
                      : UrgentLog.cw.status === 'FIX'
                      ? 'btn btn-outline-success'
                      : '',
                  ]"
                  @click="
                    UrgentLog.cw.status === 'NOFIX'
                      ? reqRejectLog('CW')
                      : UrgentLog.cw.status === 'FIX'
                      ? approvalRejectLog('CW')
                      : null
                  "
                >
                  {{
                    UrgentLog.cw.status === "NOFIX"
                      ? "Confirm Fix Reject CW"
                      : UrgentLog.cw.status === "FIX"
                      ? "Pass Inspection CW"
                      : ""
                  }}
                </button>
                <p class="text-danger font-600 font-0-8s m-0 p-0">
                  <b-icon
                    icon="exclamation-circle"
                    animation="fade"
                    font-scale="1"
                    class="mx-1"
                  ></b-icon
                  >Copy writer ยังได้รับการ
                  {{
                    UrgentLog.cw.status === "NOFIX"
                      ? "เเก้ไขการ Reject"
                      : UrgentLog.cw.status === "FIX"
                      ? "ตรวจสอบหลังจากการเเก้ไข้ Reject"
                      : ""
                  }}
                </p>
              </div>
              <div
                class="row mb-2 align-items-center"
                v-if="UrgentLog.gd !== null"
              >
                <button
                  v-if="
                    UrgentLog.gd.status === 'NOFIX'
                      ? $roleaction('Req Image')
                      : UrgentLog.gd.status === 'FIX'
                      ? $roleaction('Approval Graphic design')
                      : false
                  "
                  type="button"
                  class="btn btn-sm "
                  :class="[
                    UrgentLog.gd.status === 'NOFIX'
                      ? 'btn-outline-secondary'
                      : UrgentLog.gd.status === 'FIX'
                      ? 'btn btn-outline-success'
                      : '',
                  ]"
                  @click="
                    UrgentLog.gd.status === 'NOFIX'
                      ? reqRejectLog('GD')
                      : UrgentLog.gd.status === 'FIX'
                      ? approvalRejectLog('GD')
                      : null
                  "
                >
                  {{
                    UrgentLog.gd.status === "NOFIX"
                      ? "Confirm Fix Reject GD"
                      : UrgentLog.gd.status === "FIX"
                      ? "Pass Inspection GD"
                      : ""
                  }}
                </button>
                <p class="text-danger font-600 m-0 p-0  font-0-8s">
                  <b-icon
                    icon="exclamation-circle"
                    animation="fade"
                    font-scale="1"
                    class="mx-1"
                  ></b-icon
                  >Graphic Design ยังได้รับการ
                  {{
                    UrgentLog.gd.status === "NOFIX"
                      ? "เเก้ไขการ Reject"
                      : UrgentLog.gd.status === "FIX"
                      ? "ตรวจสอบหลังจากการเเก้ไข้ Reject"
                      : ""
                  }}
                </p>
              </div>
            </div>
          </div>
          <!-- -------------------------- -->
          <div class="col-6">
            <div class="row justify-content-end">
              <button
                type="button"
                class="btn btn-sm px-4 bt-main1 mx-1"
                @click="
                  $router.push({
                    name: 'Enrich Product',
                  })
                "
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-sm px-4 bt-bnb mx-1"
                @click="view()"
              >
                View
              </button>
              <button
                type="button"
                class="btn btn-sm bt-vfx mx-1"
                v-if="
                  can_update &&
                    ($roleaction('Req Image') ||
                      $roleaction('Req Missing Data'))
                "
                @click="savevdo()"
              >
                Save Videos
              </button>
              <button
                type="button"
                class="btn btn-sm bt mx-1"
                v-if="
                  can_update &&
                    $roleaction('Req Image') &&
                    (enrich == 'I' && can_update_gd
                      ? true
                      : enrich == 'C' ||
                        enrich == 'Y' ||
                        enrich == 'P' ||
                        enrich == 'R' ||
                        enrich == 'A'
                      ? true
                      : false)
                "
                @click="saveimg()"
              >
                Save Images
              </button>
              <button
                v-if="
                  can_update &&
                    $roleaction('Req Missing Data') &&
                    (enrich == 'I' && can_update_cw
                      ? true
                      : enrich == 'C' ||
                        enrich == 'Y' ||
                        enrich == 'P' ||
                        enrich == 'R' ||
                        enrich == 'A'
                      ? true
                      : false)
                "
                type="button"
                class="btn btn-sm px-4 bt-main mx-1"
                @click="update()"
              >
                Save Content
              </button>
              <!-- {{ enrich }} -->
            </div>
          </div>

          <!-- <button type="button" class="btn btn-sm px-4 bt-mainup mx-1">
            Approve
          </button> -->
        </div>
      </div>
      <div class="col-2 m-0 pr-0">
        <div class="m-0 px-0 pt-1 pb-5 w-100 sticky-top">
          <div class="row m-0 p-0 w-100">
            <div class="col-12 m-0 p-0">
              <p
                class="m-0 py-2 px-3"
                :class="
                  scrollnow == 'Productimages'
                    ? 'scrollselect'
                    : 'scrollunselect'
                "
                type="button"
                @click="scroll('Productimages')"
              >
                รูปภาพเเละวิดีโอของสินค้า
              </p>
              <p
                class="m-0 py-2 px-3"
                :class="
                  scrollnow == 'Generalinfomation'
                    ? 'scrollselect'
                    : 'scrollunselect'
                "
                type="button"
                @click="scroll('Generalinfomation')"
              >
                ข้อมูลทั่วไป
              </p>

              <p
                class="m-0 py-2 px-3"
                :class="
                  scrollnow == 'Productdescription'
                    ? 'scrollselect'
                    : 'scrollunselect'
                "
                type="button"
                @click="scroll('Productdescription')"
              >
                คำอธิบายของสินค้า
              </p>
              <p
                class="m-0 py-2 px-3"
                :class="
                  scrollnow == 'Productremark'
                    ? 'scrollselect'
                    : 'scrollunselect'
                "
                type="button"
                @click="scroll('Productremark')"
              >
                Remark ของสินค้า
              </p>
              <p
                class="m-0 py-2 px-3"
                :class="
                  scrollnow == 'Productother'
                    ? 'scrollselect'
                    : 'scrollunselect'
                "
                type="button"
                @click="scroll('Productother')"
              >
                อื่น ๆ
              </p>
            </div>
            <!-- {{ scrollnow }} -->
          </div>
          <div class="row w-100 m-0 p-0">
            <div class="col-12 m-0 p-0">
              <Access :key="$route.params.skcode" @get_data="get_data" />
            </div>

            <!-- <div class="col-12 text-center mt-4" v-if="scrolldown > 500">
              <button
                type="button"
                class="btn bt-transparent"
                @click="gototop()"
              >
                <b-avatar
                  variant="light"
                  icon="caret-up-fill"
                  class="text-main"
                  size="3em"
                >
                </b-avatar>
              </button>
            </div> -->
          </div>
        </div>
      </div>
      <!-- -------------------- got to top ----------- -->
      <div class="m-0 p-0 gototop" v-if="scrolldown > 500">
        <button type="button" class="btn bt-transparent" @click="gototop()">
          <b-avatar
            variant="light"
            icon="caret-up-fill"
            class="text-main"
            size="3em"
          >
          </b-avatar>
        </button>
      </div>
    </div>
    <ModalReject :key="reModal" :propsData="dataModal" />
    <ModalUrgentApproval
      :key="keyUrgent"
      :propsData="dataUrgent"
      @calldata="get_data"
    />
  </div>
</template>

<script>
import Generalinfomation from "@/components/Products/EnrichProductDetail/Generalinfomation.vue";
import Productimages from "@/components/Products/EnrichProductDetail/Productimages.vue";
import Productdescription from "@/components/Products/EnrichProductDetail/Productdescription.vue";
import Productremark from "@/components/Products/EnrichProductDetail/Productremark.vue";
import Productother from "@/components/Products/EnrichProductDetail/Productother.vue";
import Boxdetail from "@/components/Products/EnrichProductDetail/Boxdetail.vue";
import Access from "@/components/Products/EnrichProductDetail/Access.vue";
import ModalReject from "../../components/Products/EnrichProductDetail/ModalReject/ModalRejectRemark.vue";
import ModalUrgentApproval from "@/components/Products/EnrichProductDetail/UrgentApproval/ModalUrgentApproval.vue";

import { getUrgentLogEnrich } from "@/api/Product";

import Button from "../../components/common/Button.vue";
export default {
  name: "EnrichProductDetail",
  components: {
    Generalinfomation,
    Productimages,
    Productdescription,
    Productremark,
    Productother,
    Boxdetail,
    Access,
    ModalReject,
    Button,
    ModalUrgentApproval,
  },
  data() {
    return {
      reModal: Date.now() + 150,
      modalShow: false,
      load: true,
      margintop: false,
      scrollnow: "Generalinfomation",
      scrolldown: 0,
      isEditing: false,
      enrich: "",
      can_update: false,
      can_update_cw: false,
      can_update_gd: false,
      CATBNB: "",
      CATTWD: "",
      remark2: "",
      dataModal: { type: "" },
      dataUrgent: "",
      keyUrgent: Date.now() + 250,
      UrgentLog: { gd: null, cw: null },
    };
  },
  watch: {
    com_get_data: function(v) {
      this.get_data_assing();

      if (v !== null) {
        this.load = false;

        let { CATBNB, CATTWD } = v;

        this.CATBNB = CATBNB;
        this.CATTWD = CATTWD;
        if (this.enrich !== "A" && String(CATTWD).length < 6) {
          this.$serviceMain.showWarningAlert1(
            this,
            `Please Check PWB Category Before Approved`
          );

          // this.$bvToast.toast("Please Check TWD/BNB Category Before Approved", {
          //   title: `Warning!`,
          //   variant: "danger",
          //   toaster: "b-toaster-top-left",
          //   solid: true,
          //   autoHideDelay: 10000,
          // });
        }
      } else {
        this.load = true;
      }
    },

    com_get_data_assing_preqc: async function(v) {
      await this.get_data_assing_preqc();
    },

    // scrollProductdescription: function (v) {
    // },
  },
  computed: {
    com_get_data: function() {
      return this.$store.getters["enrichproductdetailActions/get_data"];
    },
    com_get_data_assing_preqc: function() {
      return this.$store.getters["assigntasksActions/get_dataassign"];
    },
    // scrollProductdescription: function () {
    //   return window.addEventListener('scroll', this.handleScroll);
    // },
  },
  beforeCreate() {},

  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },

  async mounted() {
    this.scrollinit();
    this.getpimenrichproduct();
    await this.get_data_assing_preqc();
  },
  // created() {

  // },
  async destroyed() {
    await this.$store.dispatch("assigntasksActions/dataassign_null");
    await this.$store.dispatch("enrichproductdetailActions/setnull");
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async approvalRejectLog(params) {
      try {
        let getdata = await getUrgentLogEnrich({
          skCode: this.$route.params.skcode,
        });

        let data = getdata.data;
        if (data.length > 0) {
          let mss = `ยืนยันการบันทึก/ Confirm record ?`;
          let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
          if (confirm) {
            let get_data = await this.$store.getters[
              "enrichproductdetailActions/get_data"
            ];
            const { assign_Id, enrich } = get_data;

            let dataUrgent =
              params === "CW" ? this.UrgentLog.cw : this.UrgentLog.gd;
            let index = data.findIndex((e) => e.role === params);
            if (index === -1) {
              throw `ข้อมูลมีการเปลี่ยนเเปลงไปเเล้วก่อนหน้านี้ กรุณารีโหลดเเล้วทำรายการอีกครั้ง`;
            }
            let { status: newstatus } = data[index];
            let { status } = dataUrgent;
            if (status !== newstatus) {
              throw `ข้อมูลมีการเปลี่ยนเเปลงไปเเล้วก่อนหน้านี้ กรุณารีโหลดเเล้วทำรายการอีกครั้ง`;
            }
            dataUrgent.rejectType = "CR";
            dataUrgent.old_enrich = enrich;
            dataUrgent.new_enrich = enrich;
            dataUrgent.role = params;
            dataUrgent.update_By = this.$store.getters[
              "loginActions/GET_USER"
            ].id;

            let _update_enrich = await this.$store.dispatch(
              "enrichproductdetailActions/update_enrich",
              dataUrgent
            );
            //----- Update enrich -----
            if (_update_enrich !== "OK") {
              // console.log(_update_enrich);
              throw _update_enrich;
            }

            this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
            this.getpimenrichproduct();
          } else {
            throw `ข้อมูลมีการเปลี่ยนเเปลงไปเเล้วก่อนหน้านี้ กรุณารีโหลดเเล้วทำรายการอีกครั้ง`;
          }
        }
      } catch (error) {
        this.getpimenrichproduct();
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async reqRejectLog(params) {
      try {
        let getdata = await getUrgentLogEnrich({
          skCode: this.$route.params.skcode,
        });

        let data = getdata.data;
        if (data.length > 0) {
          let mss = `ยืนยันการบันทึก/ Confirm record ?`;
          let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
          if (confirm) {
            let get_data = await this.$store.getters[
              "enrichproductdetailActions/get_data"
            ];
            const { assign_Id, enrich } = get_data;

            let dataUrgent =
              params === "CW" ? this.UrgentLog.cw : this.UrgentLog.gd;
            let index = data.findIndex((e) => e.role === params);
            if (index === -1) {
              throw `ข้อมูลมีการเปลี่ยนเเปลงไปเเล้วก่อนหน้านี้ กรุณารีโหลดเเล้วทำรายการอีกครั้ง`;
            }
            let { status: newstatus } = data[index];
            let { status } = dataUrgent;
            if (status !== newstatus) {
              throw `ข้อมูลมีการเปลี่ยนเเปลงไปเเล้วก่อนหน้านี้ กรุณารีโหลดเเล้วทำรายการอีกครั้ง`;
            }
            dataUrgent.rejectType = "FR";
            dataUrgent.old_enrich = enrich;
            dataUrgent.new_enrich = enrich;
            dataUrgent.role = params;
            dataUrgent.update_By = this.$store.getters[
              "loginActions/GET_USER"
            ].id;

            let _update_enrich = await this.$store.dispatch(
              "enrichproductdetailActions/update_enrich",
              dataUrgent
            );
            //----- Update enrich -----
            if (_update_enrich !== "OK") {
              // console.log(_update_enrich);
              throw _update_enrich;
            }

            this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
            this.getpimenrichproduct();
          } else {
            throw `ข้อมูลมีการเปลี่ยนเเปลงไปเเล้วก่อนหน้านี้ กรุณารีโหลดเเล้วทำรายการอีกครั้ง`;
          }
        }
      } catch (error) {
        this.getpimenrichproduct();
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getDeatilReject(skCode, role) {
      let data = {
        skCode: skCode,
        role: role,
      };
      let getAPI = await this.$serviceAPI.call_API(
        "post",
        `product/getrejectremark`,
        data,
        1
      );
      let msgR = [];
      if (role === "CW") {
        msgR = getAPI.data[0].remarkRejectCW.replaceAll(",", "<br>");
        // msg = (getAPI.data[0].remarkRejectCW)
      } else {
        msgR = getAPI.data[0].remarkRejectGD.replaceAll(",", "<br>");
        // msg = (getAPI.data[0].remarkRejectGD)
      }
      console.log(msgR);
      let msgN = [];
      msgN = getAPI.data[0].Remark.replaceAll(",", "<br>");
      console.log(msgN);

      await this.$serviceMain.showRejectionAlert(
        this,
        "Remarks of rejection",
        msgR,
        msgN
      );
    },
    async check_enrich() {
      setTimeout(async () => {
        let get_data = await this.$store.getters[
          "enrichproductdetailActions/get_data"
        ];

        const { enrich } = get_data;
        let _enrich = String(enrich).trim();
        if (_enrich !== "" && _enrich !== "I") {
        } else {
          let mss = `สินค้ารอการตรวจสอบข้อมูล / The product is awaiting verification`;
          this.$serviceMain.showWarningAlert1(this, mss);
        }
      }, 200);
    },
    async Approval(d) {
      try {
        // --------------- get all status fof pre qc again before save for update last status -------
        await this.$store.dispatch("assigntasksActions/call_dataassign_again");
        // --------------- end get all status fof pre qc again before save for update last status -------
        let data = await this.$store.getters[
          "assigntasksActions/get_dataassign"
        ];

        let get_data = await this.$store.getters[
          "enrichproductdetailActions/get_data"
        ];

        const { assign_Id, enrich } = get_data;

        let mss;
        if (d == "CW") mss = `อนุมัติ / Approval Copy Writer?`;
        if (d == "GD") mss = `อนุมัติ / Approval Graphic Design?`;

        const {
          copyWr_Creator_Status,
          graphicDsn_Creator_Status,
          senior_copyWr_Staus,
          senior_graphicDsn_Status,
        } = data;

        const CR_type =
          d == "CW" ? copyWr_Creator_Status : graphicDsn_Creator_Status;
        const SR_type =
          d == "CW" ? senior_copyWr_Staus : senior_graphicDsn_Status;

        if (CR_type == "R" || SR_type == "Y") {
          this.get_data();
          throw "Sku นี้อยู่ในสถานะ Reject ไม่สามารถทำงานอนุมัติได้ ";
        }

        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          if (d == "CW") {
            data.senior_copyWr = await this.$store.getters[
              "loginActions/GET_USER"
            ].id;
            data.senior_copyWr_Staus = "Y";
          } else if (d == "GD") {
            data.senior_graphicDsn = await this.$store.getters[
              "loginActions/GET_USER"
            ].id;

            data.senior_graphicDsn_Status = "Y";
          }

          if (
            data.senior_copyWr_Staus == "Y" &&
            data.senior_graphicDsn_Status == "Y"
          ) {
            data.approved_Date = new Date();
            //----- Update enrich -----
            let val = {
              assign_Id: assign_Id,
              skCode: data.skCode,
              old_enrich: enrich,
              new_enrich: "A",
              update_By: this.$store.getters["loginActions/GET_USER"].id,
              rejectType: "AP",
              role: d,
            };
            let _update_enrich = await this.$store.dispatch(
              "enrichproductdetailActions/update_enrich",
              val
            );
            //----- Update enrich -----
            if (_update_enrich !== "OK") {
              this.get_data();
              console.log(_update_enrich);
              throw _update_enrich;
            }
          }

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `access/accessuser`,
            data,
            1
          );

          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.get_data();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async UrgentApproval(params) {
      try {
        // --------------- get all status fof pre qc again before save for update last status -------
        await this.$store.dispatch("assigntasksActions/call_dataassign_again");
        // // --------------- end get all status fof pre qc again before save for update last status -------
        let data = await this.$store.getters[
          "assigntasksActions/get_dataassign"
        ];
        let get_data = await this.$store.getters[
          "enrichproductdetailActions/get_data"
        ];

        const { senior_graphicDsn_Status, senior_copyWr_Staus } = data;

        this.dataUrgent = params;
        this.keyUrgent = Date.now();
        let type =
          params == "CW" ? senior_copyWr_Staus : senior_graphicDsn_Status;
        // if (type !== "R") {
        //   this.get_data();
        //   throw "ไม่สามารถ Urgent Approval ได้เนื่องจากไม่อยู่ในสถานะ Reject ";
        // }
        setTimeout(async () => {
          await this.$bvModal.show("ModalUrgentApproval");
        }, 100);
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async Reject(params) {
      try {
        // --------------- get all status fof pre qc again before save for update last status -------
        await this.$store.dispatch("assigntasksActions/call_dataassign_again");
        // // --------------- end get all status fof pre qc again before save for update last status -------
        let data = await this.$store.getters[
          "assigntasksActions/get_dataassign"
        ];
        let get_data = await this.$store.getters[
          "enrichproductdetailActions/get_data"
        ];

        const { copyWr_Creator_Status, graphicDsn_Creator_Status } = data;

        this.dataModal.type = params;
        this.reModal = Date.now();
        const type =
          params == "CW" ? copyWr_Creator_Status : graphicDsn_Creator_Status;
        if (type == "R" && type != "Y") {
          this.get_data();
          throw "Sku นี้ไม่อยู่ในสถานะ Reject";
        } else {
          setTimeout(async () => {
            await this.$bvModal.show("ModalReject");
          }, 100);
        }

        // let result = await this.$serviceMain.showtextareaAlert(
        //   this,
        //   mss,
        //   "โปรดระบุหมายเหตุ ปฎิเสธอนุมัติขึ้น WEB"
        // );

        // if (result.isConfirmed) {
        //   if (params == "CW") {
        //     data.senior_copyWr = await this.$store.getters[
        //       "loginActions/GET_USER"
        //     ].id;
        //     data.copyWr_Creator_Status = "R";
        //     data.senior_copyWr_Staus = "R";
        //     data.remarkRejectCW = result.value;
        //   } else if (params == "GD") {
        //     data.senior_graphicDsn = await this.$store.getters[
        //       "loginActions/GET_USER"
        //     ].id;
        //     data.graphicDsn_Creator_Status = "R";
        //     data.senior_graphicDsn_Status = "R";
        //     data.remarkRejectGD = result.value;
        //   }
        //   data.approved_Date = new Date();

        //   //----- Update enrich -----
        //   let val = {
        //     assign_Id: assign_Id,
        //     skCode: data.skCode,
        //     old_enrich: enrich,
        //     new_enrich: "R",
        //     update_By: this.$store.getters["loginActions/GET_USER"].id,
        //   };
        //   let _update_enrich = await this.$store.dispatch(
        //     "enrichproductdetailActions/update_enrich",
        //     val
        //   );
        //   //----- Update enrich -----
        //   if (_update_enrich !== "OK") {
        //     console.log(_update_enrich);
        //     throw _update_enrich;
        //   }
        //   let getAPI = await this.$serviceAPI.call_API(
        //     "post",
        //     `access/accessuser`,
        //     data,
        //     1
        //   );
        //   this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        //   this.get_data();
        // }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async RequestApproval(d) {
      try {
        // --------------- get all status fof pre qc again before save for update last status -------
        await this.$store.dispatch("assigntasksActions/call_dataassign_again");
        // --------------- end get all status fof pre qc again before save for update last status -------
        let data = await this.$store.getters[
          "assigntasksActions/get_dataassign"
        ];
        // ----------------- Check sku for support Enrich Y from old pim can not req approve becouce sku = '' in body --------------------
        data.skCode =
          (await this.$serviceMain.chechnull(data.skCode)) == ""
            ? this.$route.params.skcode
            : data.skCode;
        // ---------------------------------------
        let get_data = await this.$store.getters[
          "enrichproductdetailActions/get_data"
        ];

        const { assign_Id, enrich } = get_data;

        let mss;
        if (d == "CW") mss = `ขออนุมัติ / Request Approval Copy Writer?`;
        if (d == "GD") mss = `ขออนุมัติ / Request Approval Graphic Design?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          if (d == "CW") {
            data.copyWr_Creator = await this.$store.getters[
              "loginActions/GET_USER"
            ].id;
            data.senior_copyWr_Staus = "N";
            data.copyWr_Creator_Status = "Y";
          } else if (d == "GD") {
            data.graphicDsn_Creator = await this.$store.getters[
              "loginActions/GET_USER"
            ].id;
            data.senior_graphicDsn_Status = "N";
            data.graphicDsn_Creator_Status = "Y";
          }

          if (
            data.copyWr_Creator_Status == "Y" &&
            data.graphicDsn_Creator_Status == "Y"
          ) {
            //----- Update enrich -----
            let val = {
              assign_Id: assign_Id,
              skCode: data.skCode,
              old_enrich: enrich,
              new_enrich: "P",
              update_By: this.$store.getters["loginActions/GET_USER"].id,
              rejectType: "REQ",
              role: d,
            };
            let _update_enrich = await this.$store.dispatch(
              "enrichproductdetailActions/update_enrich",
              val
            );
            //----- Update enrich -----
            if (_update_enrich !== "OK") {
              console.log(_update_enrich);
              throw _update_enrich;
            }
          }

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `access/accessuser`,
            data,
            1
          );

          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.get_data();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getuseraccessbyid() {
      this.$store.dispatch("assigntasksActions/call_dataassign");

      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async savevdo() {
      try {
        let data = await this.$store.getters[
          "enrichproductdetailActions/get_data"
        ];
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          // ---------------------- save img -------------------
          // let getAPI = await this.$serviceAPI.call_API(
          //   "post",
          //   `update/product`,
          //   data,
          //   1
          // );
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `product/updatepimproduct_vdo`,
            data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");

          this.getpimenrichproduct();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async saveimg() {
      try {
        let data = await this.$store.getters[
          "enrichproductdetailActions/get_data"
        ];
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          // ---------------------- save img -------------------
          let url_img = `${this.$cons.URL_TWD_IMG}img/save`;

          var config_axios = {
            method: "post",
            url: url_img,
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };

          this.$store.dispatch("loadingActions/onload");

          let save_img = await this.$axios(config_axios);
          // ---------------------- save img -------------------
          // let getAPI = await this.$serviceAPI.call_API(
          //   "post",
          //   `update/product`,
          //   data,
          //   1
          // );
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `product/updatepimproduct_img`,
            data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");

          this.getpimenrichproduct();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async update() {
      try {
        let data = await this.$store.getters[
          "enrichproductdetailActions/get_data"
        ];
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          // let getAPI = await this.$serviceAPI.call_API(
          //   "post",
          //   `update/product`,
          //   data,
          //   1
          // );
          delete data.fileUploads;
          delete data.fileUploadsmain;
          delete data.longDescEN_1;
          delete data.longDescTH_1;
          delete data.longDescTH_V;
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `product/updatepimproduct`,
            data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.getpimenrichproduct();
        }
      } catch (error) {
        this.$store.dispatch("loadingActions/onunload");
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    view() {
      let routeData = this.$router.resolve({
        name: "view Product",
        params: { skcode: this.$route.params.skcode },
      });
      window.open(routeData.href, "_blank");
    },
    async get_data_assing_preqc() {
      return new Promise(async (resolve, reject) => {
        let data = await this.$store.getters[
          "assigntasksActions/get_dataassign"
        ];
        if (data !== null) {
          // console.log(data);
          let {
            push_img_already,
            req_Image_Done,
            contactVd_Gd_Status,
            push_data_already,
            missing_Data_Done,
            contactVd_Cw_Status,
          } = data;

          this.can_update_gd =
            push_img_already == "Y" ||
            req_Image_Done == "Y" ||
            contactVd_Gd_Status == "Y"
              ? true
              : false;
          this.can_update_cw =
            push_data_already == "Y" ||
            missing_Data_Done == "Y" ||
            contactVd_Cw_Status == "Y"
              ? true
              : false;

          // console.log(this.can_update_cw);
          // console.log(this.can_update_gd);
          resolve("OK");
        } else {
          resolve("OK");
        }
      });
    },
    async get_data_assing() {
      let data = this.$store.getters["enrichproductdetailActions/get_data"];

      if (data !== null) {
        const { enrich } = data;

        //enrich
        this.enrich = String(enrich).trim();
        this.dissable_update();
        this.check_enrich();
      }
    },
    dissable_update() {
      let enrich = this.enrich;

      // if (enrich !== "" && enrich !== "I") {
      //   this.can_update = true;
      // }
      if (enrich !== "") {
        this.can_update = true;
      } else {
        this.can_update = false;
      }
    },
    preventNav(event) {
      // if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },

    async getpimenrichproduct() {
      await this.$store.dispatch(
        "enrichproductdetailActions/getpimenrichproduct_data",
        this.$route.params.skcode
      );
      await this.$store.dispatch(
        "enrichproductdetailActions/getpimenrichproduct_att",
        this.$route.params.skcode
      );
      this.getuseraccessbyid();
      this.getUrgentApproveLog();
    },
    async getUrgentApproveLog() {
      try {
        let gd = null;
        let cw = null;
        let getdata = await getUrgentLogEnrich({
          skCode: this.$route.params.skcode,
        });

        let data = getdata.data;
        if (data.length > 0) {
          let indexCW = data.findIndex(
            (e) =>
              String(e.role)
                .toLocaleUpperCase()
                .trim() === "CW"
          );
          let indexGD = data.findIndex(
            (e) =>
              String(e.role)
                .toLocaleUpperCase()
                .trim() === "GD"
          );

          if (indexCW !== -1) {
            cw = data[indexCW];
          }
          if (indexGD !== -1) {
            gd = data[indexGD];
          }
          this.UrgentLog.gd = gd;
          this.UrgentLog.cw = cw;
        } else {
          this.UrgentLog.gd = gd;
          this.UrgentLog.cw = cw;
        }
        // console.log(data);
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async get_data() {
      await this.$store.dispatch(
        "enrichproductdetailActions/getpimenrichproduct_data",
        this.$route.params.skcode
      );
      this.getuseraccessbyid();
    },

    handleScroll(e) {
      // console.log(e.path[1].scrollY);
      // console.log(window.scrollY);
      // let scrollY = e.path[1].scrollY
      let scrollY = window.scrollY;
      this.scrolldown = scrollY;
      // console.log(e);

      // if (scrollY > 0) {
      //   this.margintop = true;
      // } else {
      //   this.margintop = false;
      // }
      // const Generalinfomation = this.getscrollHeight("Generalinfomation");
      // const Productimages =
      //   Generalinfomation + this.getscrollHeight("Productimages");
      // const Productdescription =
      //   Productimages + this.getscrollHeight("Productdescription") - 200;
      // const Productremark =
      //   Productdescription + this.getscrollHeight("Productremark");
      // const Productother = Productremark + this.getscrollHeight("Productother");

      // if (scrollY >= 0 && scrollY <= Generalinfomation) {
      //   // console.log("Generalinfomation");
      //   this.scrollnow = "Generalinfomation";
      // } else if (scrollY >= Generalinfomation && scrollY <= Productimages) {
      //   // console.log("Productimages");
      //   this.scrollnow = "Productimages";
      // } else if (scrollY >= Productimages && scrollY <= Productdescription) {
      //   // console.log("Productdescription");
      //   this.scrollnow = "Productdescription";
      // } else if (scrollY >= Productdescription && scrollY <= Productremark) {
      //   // console.log("Productremark");
      //   this.scrollnow = "Productremark";
      // } else if (scrollY >= Productremark && scrollY <= Productother) {
      //   // console.log("Productother");
      //   this.scrollnow = "Productother";
      // }
      // ------------------------
      // const Boxdetail = this.getscrollHeight("Boxdetail");
      // const Generalinfomation =
      //   Boxdetail + this.getscrollHeight("Generalinfomation");
      // const Productdescription =
      //   Generalinfomation + this.getscrollHeight("Productdescription");
      // const Productremark =
      //   Productdescription + this.getscrollHeight("Productremark");
      // const Productother = Productremark + this.getscrollHeight("Productother");
      // const Productimages =
      //   Productother + this.getscrollHeight("Productimages");

      // if (scrollY >= 0 && scrollY <= Generalinfomation) {
      //   // console.log("Generalinfomation");
      //   this.scrollnow = "Generalinfomation";
      // } else if (
      //   scrollY >= Generalinfomation &&
      //   scrollY <= Productdescription
      // ) {
      //   // console.log("Productimages");
      //   this.scrollnow = "Productdescription";
      // } else if (scrollY >= Productdescription && scrollY <= Productremark) {
      //   // console.log("Productdescription");
      //   this.scrollnow = "Productremark";
      // } else if (scrollY >= Productremark && scrollY <= Productother) {
      //   // console.log("Productremark");
      //   this.scrollnow = "Productother";
      // } else if (scrollY >= Productother && scrollY <= Productimages) {
      //   // console.log("Productother");
      //   this.scrollnow = "Productimages";
      // }

      // ------------------------
      const Boxdetail = this.getscrollHeight("Boxdetail");
      const Productimages = Boxdetail + this.getscrollHeight("Productimages");

      const Generalinfomation =
        Productimages + this.getscrollHeight("Generalinfomation");
      const Productdescription =
        Generalinfomation + this.getscrollHeight("Productdescription");
      const Productremark =
        Productdescription + this.getscrollHeight("Productremark");
      const Productother = Productremark + this.getscrollHeight("Productother");

      if (scrollY >= 0 && scrollY <= Productimages) {
        // console.log("Generalinfomation");
        this.scrollnow = "Productimages";
      } else if (scrollY >= Productimages && scrollY <= Generalinfomation) {
        // console.log("Productimages");
        this.scrollnow = "Generalinfomation";
      } else if (
        scrollY >= Generalinfomation &&
        scrollY <= Productdescription
      ) {
        // console.log("Productdescription");
        this.scrollnow = "Productdescription";
      } else if (scrollY >= Productdescription && scrollY <= Productremark) {
        // console.log("Productremark");
        this.scrollnow = "Productremark";
      } else if (scrollY >= Productremark && scrollY <= Productother) {
        // console.log("Productother");
        this.scrollnow = "Productother";
      }
    },
    getscrollHeight(id) {
      return document.getElementById(`${id}`).scrollHeight;
    },
    gototop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    scrollinit() {
      // document.getElementById("EnrichProduct").scrollIntoView({
      //   behavior: "smooth",
      // });
      this.gototop();
    },
    //  getscroll() {
    //   var id = [
    //     "Generalinfomation",
    //     "Productimages",
    //     "Productdescription",
    //     "Productremark",
    //     "Productother",
    //   ];
    // },
  },

  async beforeRouteLeave(to, from, next) {
    // const answer = window.confirm(
    //   "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
    // );
    let confirm = await this.$serviceMain.showConfirmAlert(
      this,
      "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
    );
    if (confirm) {
      next();
    } else {
      next(false);
    }
  },
};
</script>
<style scoped>
.bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 80%;
  left: 0;
  z-index: 1030;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
  bottom: 0;
}

.gototop {
  position: -webkit-sticky;
  position: sticky;
  left: 90vw;
  bottom: 0;
  z-index: 1021;
}
</style>
